<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <roles-permissions-content />
    </v-container>
  </div>
</template>

<script>
import RolesPermissionsContent from "./components/RolesPermissionsContent.vue";

export default {
  components: {
    RolesPermissionsContent,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Users",
          disabled: false,
          exact: true,
          to: { name: "app-users", params: { id: this.$route.params.id } },
        },
        {
          text: "Roles & Permissions",
          disabled: true,
        },
      ],
    };
  },
};
</script>
